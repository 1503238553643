import React from 'react';
import Header from './composants/Header';
import Accueil from './composants/Accueil';
import LandingPage from './composants/Landing';
import Footer from './composants/Footer';

function App() {
  return (
    <div className="bg-[#000000]">
      <Header />
      <Accueil/>
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
