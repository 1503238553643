import React, { useEffect, useState } from 'react';

function Accueil() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Affiche le texte après un délai
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Ajustez le délai ici si nécessaire

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {/* Section 1 : Image d'introduction */}
      <section className="relative h-screen flex items-center justify-center bg-cover bg-center image-container overflow-hidden" style={{ backgroundImage: "url('/zen-1.jpg')" }}>
        {/* Effet de fondu noir */}
        <div className="absolute inset-0 bg-black transition-opacity duration-700 opacity-0 animate-fadeIn"></div>
        {/* Texte avec animation de glissement */}
        <h1 className={`text-5xl font-bold z-10 text-white text-center italic transition-transform duration-700 ${isVisible ? 'translate-y-0 opacity-100 animate-slideIn' : 'translate-y-10 opacity-0'} leading-relaxed mb-4`}>
          <span>"Osez la santé </span>
          <span className="bg-white text-[rgb(81,197,17)] px-2">Autrement</span>
          <span>"</span>
        </h1>

      </section>
    </div>
  );
}

export default Accueil;
