import React from 'react';
import Form from './Form';

function LandingPage() {
  return (
    <div className="px-4 md:px-15 bg-[#f1e9cf]">
      {/* Section 2 : Explication sur la réflexologie */}
      <section id="reflexologie" className="pt-32 -mt-28">
        <h2 className="text-3xl font-bold text-center mb-8">La réflexologie</h2>

        <div className="flex flex-col md:flex-row justify-center items-center mx-auto my-2">
            <div className="w-full md:w-1/3 p-6 bg-white rounded-lg shadow-md mx-4"> {/* Ajout de mx-2 pour l'espacement */}
            <h3 className="font-semibold mb-2">Qu'est-ce que c'est ?</h3>
            <p className="mb-4">
                La réflexologie est une thérapie douce qui vise à équilibrer le corps et à promouvoir le bien-être. 
                Elle se base sur le principe que certaines zones des pieds et des mains sont liées à des organes et systèmes du corps.
            </p>
            <h3 className="font-semibold mb-2">Bienfaits :</h3>
            <ul className="list-disc list-inside">
                <li>Réduction du stress</li>
                <li>Amélioration de la circulation sanguine</li>
                <li>Récupération après un effort physique</li>
            </ul>
            </div>

            <div className="w-full md:w-1/4 mx-4 my-2 flex justify-center"> {/* Ajout de mx-2 pour l'espacement */}
            <img src="/pied-1.jpg" alt="Réflexologie" className="w-full rounded-2xl" />
            </div>
        </div>
        </section>


      {/* Section 3 : Qui suis-je ? */}
      <section id="qui-suis-je" className="pt-32 -mt-28">
        <h2 className="text-3xl font-bold text-center mb-8">Qui suis-je ?</h2>

        <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="w-full md:w-1/4 mx-2 my-2"> {/* Ajout de mx-2 pour l'espacement */}
            <img src="/claudine-bouet-reflexologue-caen-bayeux.jpg" alt="Claudine Bouet Reflexologue Caen Bayeux" className="w-full rounded-2xl h-auto" />
            </div>
            <div className="w-full md:w-1/2 p-6 bg-white rounded-lg shadow-md mx-2 my-2"> {/* Ajout de mx-2 pour l'espacement */}
            <h3 className="font-semibold mb-2">Claudine Bouet Réflexologue entre Bayeux et Caen</h3>
            <p className="mb-4">
                Passionnée de médecine douce depuis très longtemps, il a été normal, il y a 8 ans, que je me tourne pour soulager mon mal de dos vers la réflexologie plantaire. Les séances rapprochées travaillent mes douleurs qui s’estompent. Très vite, je n’ai plus besoin que de séances plus éloignées pour entretenir ma santé et me détendre… je les attends toujours avec impatience !
                Aussi, quand la vie me donne l’occasion de me former, je n’hésite pas : la réflexologie plantaire offre tellement de bien-être et de soulagement que c’est décidé, moi aussi je veux faire du bien autour de moi !
                Je me forme pendant 8 mois, selon la méthode INGHAM, alternant les cours théoriques et la pratique sur des pieds volontaires « connus » tout d’abord puis bien vite sur des « nouveaux » pieds, le bouche à oreille ayant fonctionné !
                Les retours positifs ne se font pas attendre : meilleur sommeil, détente, soulagement des douleurs, et les enfants ne sont pas en reste avec une meilleure concentration et des maux de ventre envolés…
                En complément, je suis aussi des cours de diététique et de nutrition, convaincue depuis toujours que notre santé se trouve aussi dans notre assiette. Biochimiste de formation, ces cours complètent mes connaissances et me permettent de conseiller une alimentation saine et équilibrée, abordable par tous.
                Chaque jour, je suis heureuse d’apporter dans la vie des personnes qui viennent me voir du soulagement, de la détente et du bien-être…
            </p>
            </div>
        </div>
        </section>


      {/* Section 4 : Bien-être au travail */}
      <section id="bien-etre-au-travail" className="pt-24 -mt-24">
        <h2 className="text-3xl font-bold text-center mb-8">Bien-être au travail</h2>
        <div className="flex flex-wrap justify-center space-x-4">
          {/* Bloc 1 : Problématiques du stress */}
          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4">
            <h3 className="text-xl font-semibold mb-4">Les enjeux du stress</h3>
            <p>
              C’est prouvé, le stress est la première source d’arrêt maladie. Mal géré, il peut dégénérer en dépression. 
              Au travail, les situations stressantes sont nombreuses : le bruit, les responsabilités, les objectifs, la cohabitation… 
              Ces tensions peuvent entraîner des maux de dos, des troubles du sommeil et de l'irritabilité.
            </p>
          </div>

          {/* Bloc 2 : Bénéfices de la réflexologie pour l'employé */}
          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4">
            <h3 className="text-xl font-semibold mb-4">Bénéfices pour l’employeur</h3>
            <p>
              La réflexologie aide vos collaborateurs à gérer leur stress. Ils se sentent reconnus et épaulés, 
              sachant qu'ils appartiennent à une entreprise soucieuse de leur bien-être. 
              La circulation sanguine est relancée, et la détente favorise la concentration, l'endurance et la créativité.
            </p>
          </div>

          {/* Bloc 3 : Comment mettre en place la réflexologie */}
          <div className="md:w-1/4 p-6 bg-white rounded-lg shadow-md mb-4">
            <h3 className="text-xl font-semibold mb-4">Comment procéder ?</h3>
            <p>
              Je me déplace dans votre entreprise avec un fauteuil confortable, une couverture moelleuse et de la musique douce.
              Des séances découverte de 20 minutes et des séances complètes de 55 minutes sont proposées.
              La fréquence peut varier selon vos besoins : hebdomadaire, mensuelle, ou lors d'événements spéciaux.
            </p>
          </div>
        </div>
      </section>

      {/* Section 5 : Contact */}
      <section id="contact" className="pt-24 -mt-24">
        <h2 className="text-3xl font-bold text-center mb-8">Contactez moi</h2>
        <Form />
      </section>
    </div>
  );
}

export default LandingPage;
