import React, { useState } from 'react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false); // Ferme le menu après avoir cliqué
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Ferme le menu lorsqu'un lien est cliqué
  };

  return (
    <header className="bg-[rgb(81,197,17)] text-white p-4 fixed top-0 left-0 w-full  z-50">
      <div className="container mx-auto flex justify-between items-center px-4 md:px-0">
        {/* Icone de contact à gauche */}
        <div className="flex items-center">
          <img src="./icon_blanc.png" alt="Contact" className="w-6 h-6 mr-2" />
        </div>

        {/* Bouton Hamburger pour mobile */}
        <button
          className="text-white md:hidden"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>

        {/* Menu items */}
        <nav
          className={`${
            isMenuOpen ? 'flex' : 'hidden'
          } md:flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0 absolute md:static bg-[rgb(81,197,17)] top-14 left-0 w-full md:w-auto px-6 md:px-0`}
        >
          <a href="#reflexologie" className="hover:text-gray-300" onClick={handleLinkClick}>Reflexologie</a>
          <a href="#qui-suis-je" className="hover:text-gray-300" onClick={handleLinkClick}>Qui suis-je ?</a>
          <a href="#bien-etre-au-travail" className="hover:text-gray-300" onClick={handleLinkClick}>Bien-être au travail</a>
          <a href="#contact" className="hover:text-gray-300" onClick={handleLinkClick}>Contact</a>
        </nav>

        {/* Bouton de prise de rendez-vous à droite */}
        <div className="hidden md:block">
          <button
            onClick={scrollToContact}
            className="bg-white text-[rgb(81,197,17)] font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-200"
          >
            Prendre rendez-vous
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
